import addDay from './addDay.js';

const dateKeys = [
  'createdAt',
  'updatedAt',
  'deletedAt',
  'paymentDate',
  'orderDate',
  'startDate',
  'endDate',
  'lastOrderDate',
  'lastMeetingDate',
  'date', // ui ile ortak ve interval de ortak.
  'startTime',
  'endTime',
  'meetingDate',
  'processingDate',
];

const formatDates = (data) => {
  if (!data || !Array.isArray(data)) return data;

  return data.map((item) => {
    dateKeys.forEach((key) => {
      if (item[key]) {
        const date = new Date(item[key]);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const result = addDay(
          `${hours}:${minutes}`,
          `${year}-${month}-${day}`,
          false,
          false,
          false,
          false,
          false,
          true
        );

        const formattedDateTime = `${result.resultDate} ${result.nTime}`;
        item[key] = formattedDateTime;
      }
    });
    return item;
  });
};

export default formatDates;
