function getTimeZone(number = false) {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);

  const calculte =
    (offset <= 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);

  let result;
  if (number) {
    result =
      (parseInt(calculte.slice(1, 3)) * 60 + parseInt(calculte.slice(4, 6))) * parseInt(`${calculte.slice(0, 1)}1`); // as minutes
  }
  if (!number) {
    result = calculte; // +00:00
  }
  return result;
}

export default getTimeZone;
