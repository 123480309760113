import getTimeZone from './getTimeZone.js';

function calculateUTC(time, process = false) {
  const offsetHours = getTimeZone(true);
  const [hours, minutes] = time.split(':').map(Number);
  const checkMe = hours * 60 + minutes;

  let newHours;

  if (process) {
    newHours = checkMe - offsetHours;
    if (newHours >= 1440) {
      newHours %= 1440;
    }
    if (newHours < 0) {
      newHours += 1440;
    }
  } else {
    newHours = checkMe + offsetHours;
    if (newHours >= 1440) {
      newHours %= 1440;
    }
    if (newHours < 0) {
      newHours += 1440;
    }
  }

  const nHours = Math.floor(newHours / 60);
  const nMins = newHours % 60;
  const newTime = `${nHours < 10 ? '0' : ''}${nHours}:${nMins < 10 ? '0' : ''}${nMins}`;

  return newTime;
}

export default calculateUTC;
