import { storageKeys } from '../constants/Enum.jsx';
import calculateUTC from './calculateUTC.js';
import getTimeZone from './getTimeZone.js';

function addDay(
  time,
  date,
  discard = false,
  convert = false,
  fullDate = false,
  colormein = false,
  basictable = false,
  tableViews = false,
  tableDateOnly = false,
  redate = false
) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  let resultDate = date;

  if (redate) {
    const [hours, minutes] = time.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe + getTimeZone(true);
    if (calculte > 1440) {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    } else if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    } else {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    }
  }
  if (basictable) {
    const localeOptions = { day: '2-digit', month: 'short' };
    const locale = localStorage.getItem(storageKeys.languge);
    const valueFromInterval = time?.split('-')[0];
    const [hours, minutes] = valueFromInterval.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe + getTimeZone(true);
    if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);
      resultDate = `${nextDay.getFullYear()}-${nextDay.getMonth() + 1}-${nextDay.getDate()}`;
      const splittedDate = nextDay.toLocaleDateString(locale, localeOptions).split(' ');

      return `${splittedDate[1]} ${splittedDate[0]} ${calculateUTC(time)}`;
    } else if (calculte >= 1440) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      resultDate = `${nextDay.getFullYear()}-${nextDay.getMonth() + 1}-${nextDay.getDate()}`;
      const splittedDate = nextDay.toLocaleDateString(locale, localeOptions).split(' ');

      return `${splittedDate[1]} ${splittedDate[0]} ${calculateUTC(time)}`;
    } else {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      const splittedDate = nextDay.toLocaleDateString(locale, localeOptions).split(' ');

      return `${splittedDate[1]} ${splittedDate[0]} ${calculateUTC(time)}`;
    }
  }
  // selected sessions etc
  if (colormein) {
    const [hours, minutes] = time.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe + getTimeZone(true);
    if (calculte >= 1440) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;
      const nTime = time;
      return { nTime, resultDate };
    } else if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;
      const nTime = time;
      return { nTime, resultDate };
    } else {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;
      const nTime = time;
      return { nTime, resultDate };
    }
  }
  //filtre ye servis
  if (fullDate) {
    const [hours, minutes] = time.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe + getTimeZone(true);
    if (calculte > 0) {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    } else if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    } else {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    }
  }
  // getselected servis
  if (discard) {
    const valueFromInterval = time?.split('-')[0];
    const [hours, minutes] = valueFromInterval.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe - getTimeZone(true);
    if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;
      if (convert) {
        const formattedDate = nextDay?.toLocaleDateString('en-US', options);
        return formattedDate;
      }
      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    } else if (calculte >= 1440) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;
      if (convert) {
        const formattedDate = nextDay?.toLocaleDateString('en-US', options);
        return formattedDate;
      }
      return `${resultDate}T${calculateUTC(time, true)}:00.000Z`;
    } else {
      return `${date}T${calculateUTC(time, true)}:00.000Z`;
    }
  }
  // Table
  if (tableViews) {
    const valueFromInterval = time?.split('-')[0];
    const [hours, minutes] = valueFromInterval.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe + getTimeZone(true);
    const nTime = calculateUTC(time);
    if (calculte >= 24 * 60) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      resultDate = `${nextDay.getDate().toString().padStart(2, '0')}/${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${nextDay.getFullYear()}`;

      return { nTime, resultDate };
    } else if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);
      resultDate = `${nextDay.getDate().toString().padStart(2, '0')}/${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${nextDay.getFullYear()}`;

      return { nTime, resultDate };
    } else {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      resultDate = `${nextDay.getDate().toString().padStart(2, '0')}/${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${nextDay.getFullYear()}`;

      return { nTime, resultDate };
    }
  }
  //Table Date Only column
  if (tableDateOnly) {
    const valueFromInterval = time?.split('-')[0];
    const [hours, minutes] = valueFromInterval.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe + getTimeZone(true);

    const nTime = time;
    if (calculte >= 24 * 60) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return { nTime, resultDate };
    } else if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return { nTime, resultDate };
    } else {
      const parts = date.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      resultDate = `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getDate().toString().padStart(2, '0')}`;

      return { nTime, resultDate };
    }
  }
  //calendar all map
  if (date !== undefined && date !== null) {
    const valueFromInterval = time?.split('-')[0];
    const [hours, minutes] = valueFromInterval.split(':').map(Number);
    const checkMe = hours * 60 + minutes;
    const calculte = checkMe + getTimeZone(true);
    if (calculte >= 1440) {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      resultDate = `${nextDay.getDate().toString().padStart(2, '0')}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getFullYear()}`;
      if (convert) {
        const formattedDate = nextDay?.toLocaleDateString('en-US', options);
        return formattedDate;
      }
      return resultDate;
    } else if (calculte < 0) {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() - 1);

      resultDate = `${nextDay.getDate().toString().padStart(2, '0')}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getFullYear()}`;
      if (convert) {
        const formattedDate = nextDay?.toLocaleDateString('en-US', options);
        return formattedDate;
      }
      return resultDate;
    } else {
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate());
      resultDate = `${nextDay.getDate().toString().padStart(2, '0')}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextDay.getFullYear()}`;
      if (convert) {
        const formattedDate = nextDay?.toLocaleDateString('en-US', options);
        return formattedDate;
      }
      return resultDate;
    }
  }
}

export default addDay;

// TODO basitleştirilebilir.
// if (row.endDate) {
//   const endDate = new Date(row.endDate);
//   const now = new Date();
//   console.log(endDate > now);
//   const offset = endDate.getTimezoneOffset();
//   const localDate = new Date(endDate.getTime() - offset * 60000);

//   // Formatlanmış tarih
//   const formattedLocalDate = localDate.toLocaleString();
//   console.log(formattedLocalDate);
// }
