import config from '../config.json';

const sendEventToDataLayer = (data) => {
  const gtmConfig = config.eCommerce.GTM;
  if (window.location.hostname === 'meet2talk.online') {
    if (window[gtmConfig.datalayerName || 'dataLayer']) {
      window[gtmConfig.datalayerName || 'dataLayer'].push({
        ecommerce: null,
      });
      window[gtmConfig.datalayerName || 'dataLayer'].push({
        ...data,
      });
    }
  }
};

export default sendEventToDataLayer;
