import addDay from './addDay.js';
import calculateUTC from './calculateUTC.js';

const formatDatewithInterval = (data) => {
  if (!data || !Array.isArray(data)) return data;

  return data.map((item) => {
    if (item['date']) {
      const startHour = item.interval.split(' -')[0];
      const endHour = item.interval.split('- ')[1];
      const combinedDateTime = `${item.date}T${startHour}:00`;
      const date = new Date(combinedDateTime);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const result = addDay(`${hours}:${minutes}`, `${year}-${month}-${day}`, false, false, false, false, false, true);

      item['date'] = result.resultDate;
      item['interval'] = `${calculateUTC(startHour)} - ${calculateUTC(endHour)}`;
    }
    return item;
  });
};

export default formatDatewithInterval;
