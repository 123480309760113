import { InputType, RegexType } from '../constants/ComponentEnums.jsx';
import { REGEX } from '../constants/RegexEnums.js';

function regexValidator(type, text, regexType = '') {
  let result = true;

  if (regexType !== '') {
    switch (regexType) {
      case RegexType.Name:
        result = REGEX.NAME.test(text);
        break;
      default:
        break;
    }
  } else if (regexType === '') {
    switch (type) {
      case InputType.Text:
        result = REGEX.TEXT.test(text);
        break;
      case InputType.TextArea:
        result = REGEX.TEXT.test(text);
        break;
      case InputType.Number:
        result = REGEX.NUMBER.test(text);
        break;
      case InputType.Email:
        result = REGEX.EMAIL.test(text);
        break;
      case InputType.Password:
        result = REGEX.PASSWORD.test(text);
        break;
      case InputType.Date:
        result = true;
        // result = REGEX.DATE.test(text);
        break;
      case InputType.Tel:
        result = REGEX.TEL.test(text);
        break;
      case InputType.Url:
        result = REGEX.URL.test(text);
        break;
      default:
        result = false;
        break;
    }
  }

  return result;
}
export default regexValidator;
